<template>
    <div>
        <app-paginated-table ref="documentList" :fields="fields" :items="items" :current-page="current_page" :total-pages="total_pages" :no-filters="true" :total="total_records" :loading="loading"  @onPageChange="onPageChange" :mobileHeaders="['document_name','actions']" @onLoadMore="loadMoreDocuments()">
            <template #header>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getDocumnets(filters)" class="m-8-24"></app-text-input>
                    </div>
                </div>
            </template>
            <template #filters>
                <div class="row">
                    <!-- <div class="col-4">
                        <app-text-input v-model="filters.search" placeholder="Search" @input="getUsers()"></app-text-input>
                    </div> -->
                    <div class="col-4">
                        <app-select-box v-model="filters.status" :options="options" @onChange="getDocumnets(filters)"></app-select-box>
                    </div>
                </div>
            </template>
            <template #head(actions)>
                {{ '' }}
            </template>
            <template #cell(document_name)="data">
                <img :src="require('@/assets/images/app-icons/pdf.png')" alt="" class="mr-1">
                {{ data.item.documentName }}
            </template>
            <template #cell(collaborator)="data">
                <b-avatar-group size="32px">
                    <b-avatar
                    :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                    :text="c.email.slice(0,1).toUpperCase()"
                    :variant="colors[index]"
                    v-b-tooltip.hover.v-primary="c.email"
                  />
                  <b-avatar v-if="data.item.collaborators.length > 1" 
                    class="mx-0" 
                    :text="`+${data.item.collaborators.length-1}`"
                    variant="light-success"
                    id="tooltip-avatar"
                    v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"
                    />
                    <!-- <b-tooltip target="tooltip-avatar" variant="primary"><span v-for="(c,index) in data.item.collaborators.slice(1)" :key="index">{{c.email}}<br></span></b-tooltip> -->
                </b-avatar-group>
            </template>
            <template #cell(actions)="data">
                <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Block Level Dropdown Menu"
                right
                variant="none"
                size="sm"
                no-caret
                >
                <template #button-content>
                    <feather-icon
                        icon="MoreVerticalIcon"
                        size="18"
                    />
                </template>
                <b-dropdown-item @click="getDocumnet(data.item._id,data.item.documentName)" >Download</b-dropdown-item>
                </b-dropdown>
                
            </template>
            <template #cell(sent_by)="data">
                <b-avatar
                    class="pull-up"
                    :text="toCapitalize(data.item.sentBy.first_name.charAt(0))"
                    variant="light-primary"
                />
                {{ `${toCapitalize(data.item.sentBy.first_name)} ${toCapitalize(data.item.sentBy.last_name)}` }}
            </template>
            <template #cell(status)="data">
                <b-badge :variant="data.item.status=='sent'? 'light-success':data.item.status=='completed'?'light-primary':data.item.status=='denied'?'light-danger':data.item.status=='awaiting_signature'?'light-warning': 'light-secondary'">
                    {{ toCapitalize(data.item.status) }}
                </b-badge>
            </template>
            <template #cell(date_uploaded)="data">
                {{ getFormatedDate(data.item.createdAt,"DD MMM YYYY") }}
            </template>
            <template #cell(last_modified)="data">
                {{ getFormatedDate(data.item.updatedAt,"DD MMM YYYY") }}
            </template>
        </app-paginated-table>
    </div>
</template>
<script>
import {BTabs,BTab,BCard,BTableLite, BDropdown, BDropdownItem,BAvatarGroup, BAvatar, BBadge, VBTooltip, BTooltip} from 'bootstrap-vue'
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSelectBox from '@/components/app-components/AppSelectBox.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue'
import UpdateUser from '@/components/functional-components/admin/users/UpdateUser.vue'
import UserDetails from '@/components/functional-components/admin/users/UserDetails.vue'
import {get_documents, get_doucment_file} from '@/apis/admin/documents'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
export default{
    directives:{
        'b-tooltip':VBTooltip,
        Ripple
    },
    components:{
        BTabs,
        BTab,
        BCard,
        BTableLite,
        AppPaginatedTable,
        AppTextInput,
        BDropdown,
        BDropdownItem,
        AppSelectBox,
        AppConfirmModal,
        UpdateUser,
        UserDetails,
        BAvatarGroup,
        BAvatar,
        BBadge,
        BTooltip
    },
    data(){
        return{
            search:'',
            status_filter:{
                active:false,
                disabled:false  
            },
            fields:[
                {
                    key:'document_name',
                    label:'Document Name'
                },
                {
                    key:'collaborator',
                    label:'Collaborator'
                },
                {
                    key:'status',
                    label:'Status'
                },
                {
                    key:'sent_by',
                    label:'Sent By'
                },
                {
                    key:'date_uploaded',
                    label:'Date Uploaded'
                },
                {
                    key:'last_modified',
                    label:'Last Modified'
                },
                {
                    key:'actions',
                }
            ],
            items: [
                {
                    document_id:'150dg505' ,
                    document_name:'Rental Lease Agreement',
                    status:'sent',
                    sent_by:'Joseph Wheeler',
                    date_uploaded:'10 Mar 2022',
                },
                {
                    document_id:'150dg506' ,
                    document_name:'Rental Lease Agreement',
                    status:'sent',
                    sent_by:'Joseph Wheeler',
                    date_uploaded:'10 Apr 2012',
                },
                {
                    document_id:'150dg507' ,
                    document_name:'Rental Lease Agreement',
                    status:'sent',
                    sent_by:'Joseph Wheeler',
                    date_uploaded:'15 Mar 2022',
                },
                {
                    document_id:'150dg508' ,
                    document_name:'Rental Lease Agreement',
                    status:'sent',
                    sent_by:'Joseph Wheeler',
                    date_uploaded:'25 Mar 2023',
                },
                {
                    document_id:'150dg509' ,
                    document_name:'Rental Lease Agreement',
                    status:'sent',
                    sent_by:'Joseph Wheeler',
                    date_uploaded:'10 May 2022',
                },

            ],
            filters:{
                limit:10,
                page:1,
                search:'',
                status:'',
                startDate:null,
                endDate:null,
            },
            current_page:1,
            total_records:0,
            total_pages:0,
            status:'',
            loading:false,
            delete_id:'',
            options: [
                { value: '', text: 'Status' },
                { value: 'awaiting_signature', text: 'Awaiting Signature' },
                { value: 'denied', text: 'Denied' },
                { value: 'completed', text: 'Completed' },
            ],
            showSidebar:false,
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loadMore:false
        }
    },
    mounted(){
        this.getDocumnets(this.filters)
    },
    methods:{
        getDocumnets(filters){
            this.loading=true
            get_documents(filters).then(resp=>{
                this.loading=false             
                this.items =this.loadMore? [...this.items,...resp.data.documentsWithPendingSignatures]:resp.data.documentsWithPendingSignatures
                this.total_records=resp.data.documentsCount
                this.total_pages=resp.data.documentsCount>0?resp.data.documentsCount/this.filters.limit:0
                this.$refs.documentList.loadMore=false
                this.loadMore=false
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        onPageChange(page){
            this.filters.page=page;
            this.getDocumnets(this.filters)
        },
        getDocumnet(id,name){
            get_doucment_file(id).then(resp=>{
               this.download(resp.fileLink,name )
            })
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        },
        loadMoreDocuments(){
            this.filters.page+=1
            this.getDocumnets(this.filters)
            this.loadMore=true
        }
    }
}
</script>
<style scoped>
.m-8-24{
    margin:8px 24px !important
}
</style>